<template>
  <router-view />
</template>

<style>
* {
  padding: 0;
  margin: 0;
  font-family: Poppins;
  user-select: none;
  cursor: none;
}
body{
  overflow: hidden;
}
body::-webkit-scrollbar{
  width: 0px;
  height: 0px;
}
a{
  text-decoration: none;
  color: white;
}
</style>
